import React from 'react';
import { string } from 'prop-types';
import cx from 'classnames';

import cardWaveBlue from '../../images/card-wave-blue.svg';
import cardWaveOrange from '../../images/card-wave-orange.svg';

const propTypes = {
  title: string.isRequired,
  uri: string,
  suTitle: string.isRequired,
  text: string.isRequired,
  image: string.isRequired,
  className: string,
};

const defaultProps = {
  uri: undefined,
  className: undefined,
};

const ImageCardItem = ({
  title,
  uri,
  suTitle,
  text,
  image,
  className,
  ...props
}) => {
  const classes = cx(
    'c-image-card-item d-flex flex-column align-items-center shadow rounded-xs bg-white overflow-hidden z-3',
    className,
  );

  return (
    <a href={uri} className={classes} {...props}>
      <img src={image} alt="" className="c-image-card-image py-3" />
      <div className="position-relative w-100">
        <div className="c-image-card-txt position-relative text-center p-5 p-lg-4">
          <p className="text-white opacity-75 mb-1">{suTitle}</p>
          <h4 className="text-white mb-3">{title}</h4>
          <p className="text-white mb-0">{text}</p>
        </div>
        <div className="position-absolute h-100 w-100 start-0 bottom-0">
          <img
            src={cardWaveOrange}
            alt=""
            className="position-absolute w-100 start-0"
          />
          <img
            src={cardWaveBlue}
            alt=""
            className="position-relative w-100 start-0 bottom-0"
          />
        </div>
      </div>
    </a>
  );
};

ImageCardItem.propTypes = propTypes;
ImageCardItem.defaultProps = defaultProps;

export default ImageCardItem;
