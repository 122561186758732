import React from 'react';
import { useMedia } from 'react-use';
import { StaticImage } from 'gatsby-plugin-image';
import { FaArrowRight } from '@react-icons/all-files/fa/FaArrowRight';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Layout from '../components/Layout/Layout';
import HeroParticles from '../components/HeroParticles/HeroParticles';
import Section from '../components/Section/Section';
import SimpleCard from '../components/Cards/SimpleCard';
import SimpleCardItem from '../components/Cards/SimpleCardItem';
import ImageCard from '../components/Cards/ImageCard';
import ImageCardItem from '../components/Cards/ImageCardItem';
import TableWrapper from '../components/Table/TableWrapper';
import TableHead from '../components/Table/TableHead';
import TableHeadItem from '../components/Table/TableHeadItem';
import TableBody from '../components/Table/TableBody';
import TableBodyRow from '../components/Table/TableBodyRow';
import TableBodyItem from '../components/Table/TableBodyItem';
import TableDropdown from '../components/Table/TableDropdown';
import TableDropdownItem from '../components/Table/TableDropdownItem';
import IconButton from '../components/IconButton/IconButton';
import Banner from '../components/Banner/Banner';
import Faqs from '../components/Faqs/Faqs';
import FaqSingle from '../components/Faqs/FaqSingle';
import FloatingMenu from '../components/FloatingMenu/FloatingMenu';
import Seo from '../components/Seo';

import formIcon from '../images/form-icon.svg';
import pdfIcon from '../images/pdf-icon.svg';
import clockHandIcon from '../images/clock-hand-icon.svg';
import exchangeIcon from '../images/exchange-icon.svg';
import elevateIcon from '../images/elevate-icon.svg';
import speedIcon from '../images/speed-icon.svg';
import reduceIcon from '../images/reduce-icon.svg';
import fileIcon from '../images/file-icon.svg';
import exchangeAltIcon from '../images/exchange-alt-icon.svg';
import rocketIcon from '../images/rocket-icon.svg';
import compliantIcon from '../images/compliant-icon.svg';
import socialMediaIcon from '../images/social-media-icon.svg';
import teamIcon from '../images/team-icon.svg';
import intakeShcedulingIcon from '../images/intake-shceduling-icon.svg';
import payAltIcon from '../images/pay-alt-icon.svg';
import roadIcon from '../images/road-icon.svg';

import howDigitalIntakeWorksDesktopBg from '../images/how-digital-intake-works--desktop-bg.svg';
import findADoctorIcon from '../images/find-a-doctor-icon.svg';
import confirmationIntakeLinkIcon from '../images/confirmation-intake-link-icon.svg';
import intakeInsuranceIdIcon from '../images/intake-insurance-id-icon.svg';
import verifyInformationIcon from '../images/verify-information-icon.svg';
import appointmentRemindersIcon from '../images/appointment-reminders-icon.svg';
import digitalCheckInIcon from '../images/digital-check-in-icon.svg';
import arriveNotifyIcon from '../images/arrive-notify-icon.svg';
import followIUpIcon from '../images/follow-up-icon.svg';

import banner from '../images/banner-1.png';
import banner2 from '../images/banner-2.png';

const PatientIntakeSoftware = () => {
  const isDesktop = useMedia('(min-width: 1024px)', true);

  return (
    <Layout hasNavBg>
      <Seo
        title="Patient Intake Software | Bridge"
        description="Patient intake software to optimize scheduling, registration, check-in, and payments. Improve efficiency, data accuracy, and patient satisfaction."
        canonical="/patient-intake-software/"
      />

      <HeroParticles
        title="Patient Intake Software"
        subTitle="Streamline Your Patient Onboarding And Check-In Process With Bridge Intake"
        label="Request a Demo Now"
        uri="/view-a-demo/"
      />

      <Section id="what-is-a-patient-engagement-software?" variant="sticky">
        <Container>
          <Row>
            <Col>
              <h2 className="fw-bold">
                Optimize Your Workflow With BridgeInteract’s Patient Intake
                Solution
              </h2>
              <p className="font-size-sm mb-5">
                Available as a standalone solution or as part of the{' '}
                <a href="/patient-engagement-solution/">
                  BridgeInteract patient engagement suite
                </a>
                , Bridge Intake represents the industry’s most comprehensive
                patient intake software to enhance{' '}
                <a href="/patient-scheduling-software/">patient scheduling</a>,{' '}
                <a href="/patient-portal-self-registration-login/">
                  registration
                </a>
                , check-in, <a href="/patient-payments/">payments</a>, and
                consent form automation.
              </p>
              <h2 className="fw-bold">Intake Tailored To Your Practice</h2>
              <p className="font-size-sm mb-0">
                <strong>Unlike other patient intake solutions</strong> that
                follow a standard, one-size-fits-all formula, Bridge Intake
                empowers healthcare organizations to provide a tailored,
                seamless patient experience from the first touchpoint to
                check-in and beyond.
              </p>
            </Col>
          </Row>
          <SimpleCard className="gap-4 gap-lg-0 py-5">
            <SimpleCardItem
              col="6"
              title="Custom forms:"
              text="Bridge Intake makes it easy to create custom forms for your unique requirements, such as details of parent/guardian for pediatric practices. By focusing on exactly what you need, you can capture the data that matters to you, cut down on clutter, and save admin time."
              icon={formIcon}
              showIcon
            />
            <SimpleCardItem
              col="6"
              title="EHR agnostic:"
              text="Bridge Intake connects with your EHR system, pulling data from your existing records to pre-populate fields and allowing you to send forms on demand from your EHR interface. Data from the forms can be pushed back to your EHR database to update it and exported as PDF for easy viewing and printing."
              icon={pdfIcon}
              showIcon
            />
          </SimpleCard>
          <p className="font-size-sm mb-0">
            Learn more about patient intake software or{' '}
            <a href="/view-a-demo/">request a demo</a> to get started with
            Bridge Intake.
          </p>
        </Container>
      </Section>

      <FloatingMenu
        links={[
          'What Is Patient Intake Software?',
          'Benefits Of Patient Intake Software',
          'How Digital Intake Works: A Step-by-Step Guide',
          'Comparison: Traditional Patient Intake vs. Digital Patient Intake',
          'Why Choose BridgeInteract?',
          'Get Started With Patient Intake Software',
          'FAQs',
        ]}
      />

      <Section id="what-is-patient-intake-software?">
        <Container>
          <Row>
            <Col>
              <h2 className="fw-bolder">What Is Patient Intake Software?</h2>
              <p className="font-size-sm">
                Patient intake software automates the collection of patient
                information, syncing it directly with your practice management
                system and{' '}
                <a href="/patient-portal-software/">patient portal</a>.
              </p>
              <p className="font-size-sm">
                Digital patient intake enhances business efficiency by
                eliminating time-consuming manual data entry, making patient
                onboarding easier for everyone.
              </p>
              <p className="font-size-sm mb-5">
                Using a patient intake solution shows your practice values a
                modern,{' '}
                <a href="/blog/how-to-improve-the-patient-experience/">
                  patient-friendly experience
                </a>
                . Send intake forms via email or text, and let patients complete
                them independently.
              </p>
              <h3 className="fw-bold">
                Make Life Easier With Patient Intake Software
              </h3>
              <SimpleCard className="has-border-top-blue gap-4 gap-xl-0 py-5">
                <SimpleCardItem
                  col="6"
                  title="5 minutes saved per patient interaction"
                  text=""
                  iconRight={clockHandIcon}
                  showIconRight
                />
                <SimpleCardItem
                  col="6"
                  title="50% reduction in admin time"
                  text=""
                  iconRight={exchangeIcon}
                  showIconRight
                />
              </SimpleCard>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section id="benefits-of-patient-intake-software" variant="bg-gray">
        <Container>
          <Row>
            <Col>
              <h2 className="fw-bolder">Benefits Of Patient Intake Software</h2>
              <p className="font-size-sm">
                Implementing patient registration software is a smart step in
                your practice’s{' '}
                <a href="/blog/digital-transformation-in-healthcare/">
                  digital transformation
                </a>
                . Intake software simplifies the registration process for
                patients and reduces{' '}
                <a href="/task-management/">staff workload</a>, resulting in
                better care, higher growth potential, and{' '}
                <a href="/revenue-cycle-management/">enhanced revenues</a>.
              </p>
            </Col>
          </Row>
          <SimpleCard className="py-5">
            <SimpleCardItem
              col="4"
              title="Better Patient Experience"
              text="Patients can complete forms, upload IDs, and add documents at home or on the go—no more clipboards or long waits."
              className="mb-4"
              icon={elevateIcon}
              showIcon
            />
            <SimpleCardItem
              col="4"
              title="Faster Onboarding"
              text="Digital forms provide a step-by-step process, reducing wait times and making the first visit stress-free."
              className="mb-4"
              icon={speedIcon}
              showIcon
            />
            <SimpleCardItem
              col="4"
              title="Reduce Errors"
              text="Digital forms minimize typos, illegible handwriting, and missing information. Prompts can alert patients about any required fields."
              className="mb-4"
              icon={reduceIcon}
              showIcon
            />
            <SimpleCardItem
              col="4"
              title="Go Paperless"
              text="Cut down on office supplies and storage costs by going fully digital—no more stacks of paper."
              className="mb-4"
              icon={fileIcon}
              showIcon
            />
            <SimpleCardItem
              col="4"
              title="Eliminate Manual Data Entry"
              text="Patient details are automatically synced with your system, freeing staff from repetitive tasks."
              className="mb-4"
              icon={exchangeAltIcon}
              showIcon
            />
            <SimpleCardItem
              col="4"
              title="Boost Response Rates"
              text="Patients can complete forms anytime, on any device, which increases the likelihood of timely submissions."
              className="mb-4"
              icon={rocketIcon}
              showIcon
            />
            <SimpleCardItem
              col="4"
              title="Compliant & Secure"
              text={
                <>
                  HIPAA-compliant forms and{' '}
                  <a href="/certifications/">SOC2-certified cybersecurity</a>{' '}
                  ensure{' '}
                  <a href="/blog/healthcare-application-security-how-to-protect-patient-data/">
                    patient data is protected
                  </a>
                  .
                </>
              }
              className="mb-4 mb-xl-0"
              icon={compliantIcon}
              showIcon
            />
            <SimpleCardItem
              col="4"
              title="Mobile-Friendly Access"
              text="Patients can fill out forms on their phones, making it convenient and easy to complete them whenever they have a free moment."
              className="mb-4 mb-xl-0"
              icon={socialMediaIcon}
              showIcon
            />
            <SimpleCardItem
              col="4"
              title="Free Up Staff"
              text="Staff can focus on patient care, not paperwork."
              icon={teamIcon}
              showIcon
            />
          </SimpleCard>
          <p className="font-size-sm mb-0">
            Patient intake software is the key to saving money, retaining staff,
            and engaging patients. Learn more about{' '}
            <a href="/blog/five-ways-a-patient-intake-app-can-benefit-your-practice/">
              how a patient intake app can benefit your practice
            </a>
            .
          </p>
        </Container>
      </Section>

      <Section
        id="how-digital-intake-works:-a-step-by-step-guide"
        variant="bg-gray"
      >
        <Container>
          <Row>
            <Col>
              <h2 className="fw-bolder">
                How Digital Intake Works: A Step-By-Step Guide
              </h2>
            </Col>
          </Row>
          <Row>
            <Col>
              {isDesktop ? (
                <img
                  src={howDigitalIntakeWorksDesktopBg}
                  alt=""
                  className="w-100"
                />
              ) : (
                <ImageCard className="gap-4 py-5">
                  <img
                    src={roadIcon}
                    alt=""
                    className="c-road-icon position-relative w-auto"
                  />
                  <ImageCardItem
                    suTitle="Bridge"
                    title="Find A Doctor And Schedule Appointment"
                    text="Patient finds a doctor via the providers’ website or mobile app and books an appointment online, selecting a date and time from available slots."
                    image={findADoctorIcon}
                    className="c-item-before"
                  />
                  <img
                    src={roadIcon}
                    alt=""
                    className="c-road-icon position-relative w-auto"
                  />
                  <ImageCardItem
                    suTitle="Bridge"
                    title="Confirmation And Intake Link"
                    text="Patient receives an email or text confirmation with a secure link to complete their digital intake forms."
                    image={confirmationIntakeLinkIcon}
                  />
                  <img
                    src={roadIcon}
                    alt=""
                    className="c-road-icon position-relative w-auto"
                  />
                  <ImageCardItem
                    suTitle="Bridge"
                    title="Intake, Insurance, And ID"
                    text="Patient completes required intake forms from home using their preferred device and uploads insurance details and ID securely through the digital form."
                    image={intakeInsuranceIdIcon}
                  />
                  <img
                    src={roadIcon}
                    alt=""
                    className="c-road-icon position-relative w-auto"
                  />
                  <ImageCardItem
                    suTitle="Bridge"
                    title="Verify Information And E-Sign Documents"
                    text="Patient reviews the filled-out forms, signs consent documents digitally, and submits them."
                    image={verifyInformationIcon}
                  />
                  <img
                    src={roadIcon}
                    alt=""
                    className="c-road-icon position-relative w-auto"
                  />
                  <ImageCardItem
                    suTitle="Bridge"
                    title="Appointment Reminders"
                    text="Send reminders through email or text with the option to reschedule if needed."
                    image={appointmentRemindersIcon}
                  />
                  <img
                    src={roadIcon}
                    alt=""
                    className="c-road-icon position-relative w-auto"
                  />
                  <ImageCardItem
                    suTitle="Bridge"
                    title="Digital Check-In Before Arrival"
                    text="Patient completes the digital check-in process before arriving at the office."
                    image={digitalCheckInIcon}
                  />
                  <img
                    src={roadIcon}
                    alt=""
                    className="c-road-icon position-relative w-auto"
                  />
                  <ImageCardItem
                    suTitle="Bridge"
                    title="Arrive And Notify the Office"
                    text="The digital system can inform the office of a patient’s arrival, reducing wait time."
                    image={arriveNotifyIcon}
                  />
                  <img
                    src={roadIcon}
                    alt=""
                    className="c-road-icon position-relative w-auto"
                  />
                  <ImageCardItem
                    suTitle="Bridge"
                    title="Follow-up"
                    text="After the visit, the patient receives a summary of the consultation and follow-up instructions digitally. Further questions can be conducted through secure messaging on the portal."
                    image={followIUpIcon}
                  />
                  <img
                    src={roadIcon}
                    alt=""
                    className="c-road-icon position-relative w-auto"
                  />
                </ImageCard>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="font-size-sm fst-italic mb-0 mt-2 mt-lg-5">
                *This sequence outlines a typical digital patient intake flow
                facilitated by Bridge Intake. However, Bridge Intake is highly
                customizable and supports varied approaches.
              </p>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section
        id="comparison:-traditional-patient-intake-vs.-digital-patient-intake"
        variant="bg-gray"
      >
        <Container>
          <Row>
            <Col>
              <h2 className="fw-bolder">
                Comparison: Traditional Patient Intake vs. Digital Patient
                Intake
              </h2>
              <p className="font-size-sm mb-0">
                Switching from traditional patient intake methods to a digital
                process can transform the efficiency and overall patient
                experience within your practice. The table below highlights the
                key differences between traditional paper-based intake and
                modern digital intake:
              </p>
            </Col>
          </Row>
          <Row className="py-5">
            <Col>
              {isDesktop ? (
                <TableWrapper>
                  <TableHead>
                    <TableHeadItem
                      titleTag
                      title="Aspect"
                      colSpan={6}
                      className="bg-light"
                    />
                    <TableHeadItem
                      title="Traditional Patient Intake"
                      colSpan={3}
                      className="text-dark-blue fw-bold bg-gray-200"
                    />
                    <TableHeadItem
                      title="Digital Patient Intake"
                      colSpan={3}
                      className="text-dark-blue fw-bold bg-gray-200 align-self-start"
                    />
                  </TableHead>
                  <TableBody>
                    <TableBodyRow>
                      <TableBodyItem
                        title="Data Collection"
                        colSpan={6}
                        className="bg-white pe-4"
                      />
                      <TableBodyItem
                        className="align-content-center bg-white py-4"
                        colSpan={3}
                        text="Paper forms are manually filled out by patients."
                      />
                      <TableBodyItem
                        className="align-content-center bg-white py-4"
                        colSpan={3}
                        text="Digital forms are filled out online on any device."
                      />
                    </TableBodyRow>
                    <TableBodyRow>
                      <TableBodyItem
                        title="Data Entry"
                        colSpan={6}
                        className="bg-gray-100 pe-4"
                      />
                      <TableBodyItem
                        className="align-content-center bg-gray-100 py-4"
                        colSpan={3}
                        text="Staff manually enters patient data into the system."
                      />
                      <TableBodyItem
                        className="align-content-center bg-gray-100 py-4"
                        colSpan={3}
                        text="Automatic syncing with EHR/PM systems; no manual entry needed."
                      />
                    </TableBodyRow>
                    <TableBodyRow>
                      <TableBodyItem
                        title="Patient Experience"
                        colSpan={6}
                        className="bg-white pe-4"
                      />
                      <TableBodyItem
                        className="align-content-center bg-white py-4"
                        colSpan={3}
                        text="Long wait times and redundant paperwork in the waiting room."
                      />
                      <TableBodyItem
                        className="align-content-center bg-white py-4"
                        colSpan={3}
                        text="Patients can complete forms at home or on the go before the visit."
                      />
                    </TableBodyRow>
                    <TableBodyRow>
                      <TableBodyItem
                        title="Error Rate"
                        colSpan={6}
                        className="bg-gray-100 pe-4"
                      />
                      <TableBodyItem
                        className="align-content-center bg-gray-100 py-4"
                        colSpan={3}
                        text="High due to illegible handwriting and manual data entry."
                      />
                      <TableBodyItem
                        className="align-content-center bg-gray-100 py-4"
                        colSpan={3}
                        text="Minimal errors with guided digital forms and automated entries."
                      />
                    </TableBodyRow>
                    <TableBodyRow>
                      <TableBodyItem
                        title="Form Completion"
                        colSpan={6}
                        className="bg-white pe-4"
                      />
                      <TableBodyItem
                        className="align-content-center bg-white py-4"
                        colSpan={3}
                        text="Incomplete forms often need to be returned to patients for correction."
                      />
                      <TableBodyItem
                        className="align-content-center bg-white py-4"
                        colSpan={3}
                        text="Built-in prompts and required fields ensure complete submissions."
                      />
                    </TableBodyRow>
                    <TableBodyRow>
                      <TableBodyItem
                        title="Time & Efficiency"
                        colSpan={6}
                        className="bg-gray-100 pe-4"
                      />
                      <TableBodyItem
                        className="align-content-center bg-gray-100 py-4"
                        colSpan={3}
                        text="Staff spends significant time on administrative tasks."
                      />
                      <TableBodyItem
                        className="align-content-center bg-gray-100 py-4"
                        colSpan={3}
                        text="Staff is freed up to focus on patient care and other tasks."
                      />
                    </TableBodyRow>
                    <TableBodyRow>
                      <TableBodyItem
                        title="Document Storage"
                        colSpan={6}
                        className="bg-white pe-4"
                      />
                      <TableBodyItem
                        className="align-content-center bg-white py-4"
                        colSpan={3}
                        text="Physical storage is needed; there is a risk of loss or damage."
                      />
                      <TableBodyItem
                        className="align-content-center bg-white py-4"
                        colSpan={3}
                        text="Secure digital storage with easy access and no risk of physical loss."
                      />
                    </TableBodyRow>
                    <TableBodyRow>
                      <TableBodyItem
                        title="Patient Payments"
                        colSpan={6}
                        className="bg-gray-100 pe-4"
                      />
                      <TableBodyItem
                        className="align-content-center bg-gray-100 py-4"
                        colSpan={3}
                        text="Handled separately, often causing delays and inefficiencies."
                      />
                      <TableBodyItem
                        className="align-content-center bg-gray-100 py-4"
                        colSpan={3}
                        text="Integrated payment options within the digital intake forms."
                      />
                    </TableBodyRow>
                    <TableBodyRow>
                      <TableBodyItem
                        title="Environmental Impact"
                        colSpan={6}
                        className="bg-white pe-4"
                      />
                      <TableBodyItem
                        className="align-content-center bg-white py-4"
                        colSpan={3}
                        text="High paper usage and waste."
                      />
                      <TableBodyItem
                        className="align-content-center bg-white py-4"
                        colSpan={3}
                        text="Completely paperless; eco-friendly and sustainable."
                      />
                    </TableBodyRow>
                    <TableBodyRow>
                      <TableBodyItem
                        title="Compliance"
                        colSpan={6}
                        className="bg-gray-100 pe-4"
                      />
                      <TableBodyItem
                        className="align-content-center bg-gray-100 py-4"
                        colSpan={3}
                        text={
                          <>
                            Risk of{' '}
                            <a href="/blog/consolidate-your-software-for-patient-engagement-to-avoid-security-compliance-risks/">
                              compliance issues
                            </a>{' '}
                            with unsecured paper forms.
                          </>
                        }
                      />
                      <TableBodyItem
                        className="align-content-center bg-gray-100 py-4"
                        colSpan={3}
                        text={
                          <>
                            <a href="/blog/hipaa-compliant-healthcare-applications-part-2-of-3-what-you-need-to-know-about-audits-in-healthcare/">
                              HIPAA-compliant
                            </a>{' '}
                            digital forms with enhanced data security.
                          </>
                        }
                      />
                    </TableBodyRow>
                  </TableBody>
                </TableWrapper>
              ) : (
                <TableWrapper>
                  <TableHead>
                    <TableHeadItem
                      titleTag
                      title="Aspect"
                      colSpan={12}
                      className="bg-light"
                    />
                  </TableHead>
                  <TableBody>
                    <TableBodyRow>
                      <TableBodyItem
                        title="Traditional Patient Intake"
                        colSpan={6}
                        className="text-dark-blue fw-bold bg-gray-200 w-50"
                      />
                      <TableBodyItem
                        title="Digital Patient Intake"
                        colSpan={6}
                        className="text-dark-blue fw-bold bg-gray-200 w-50"
                      />
                    </TableBodyRow>
                    <TableBodyRow>
                      <TableBodyItem
                        title="Data Collection"
                        colSpan={12}
                        className="bg-white pe-4"
                      />
                    </TableBodyRow>
                    <TableBodyRow>
                      <TableBodyItem
                        className="align-content-start bg-white py-3 w-50"
                        colSpan={6}
                        text="Paper forms are manually filled out by patients."
                      />
                      <TableBodyItem
                        className="align-content-start bg-white py-3 w-50"
                        colSpan={6}
                        text="Digital forms are filled out online on any device."
                      />
                    </TableBodyRow>
                    <TableBodyRow>
                      <TableBodyItem
                        title="Data Entry"
                        colSpan={12}
                        className="bg-gray-100 pe-4"
                      />
                    </TableBodyRow>
                    <TableBodyRow>
                      <TableBodyItem
                        className="align-content-start bg-gray-100 py-3 w-50"
                        colSpan={6}
                        text="Staff manually enters patient data into the system."
                      />
                      <TableBodyItem
                        className="align-content-start bg-gray-100 py-3 w-50"
                        colSpan={6}
                        text="Automatic syncing with EHR/PM systems; no manual entry needed."
                      />
                    </TableBodyRow>
                    <TableBodyRow>
                      <TableBodyItem
                        title="Patient Experience"
                        colSpan={12}
                        className="bg-white pe-4"
                      />
                    </TableBodyRow>
                    <TableBodyRow>
                      <TableBodyItem
                        className="align-content-start bg-white py-3 w-50"
                        colSpan={6}
                        text="Long wait times and redundant paperwork in the waiting room."
                      />
                      <TableBodyItem
                        className="align-content-start bg-white py-3 w-50"
                        colSpan={6}
                        text="Patients can complete forms at home or on the go before the visit."
                      />
                    </TableBodyRow>
                    <TableBodyRow>
                      <TableBodyItem
                        title="Error Rate"
                        colSpan={12}
                        className="bg-gray-100 pe-4"
                      />
                    </TableBodyRow>
                    <TableBodyRow>
                      <TableBodyItem
                        className="align-content-start bg-gray-100 py-3 w-50"
                        colSpan={6}
                        text="High due to illegible handwriting and manual data entry."
                      />
                      <TableBodyItem
                        className="align-content-start bg-gray-100 py-3 w-50"
                        colSpan={6}
                        text="Minimal errors with guided digital forms and automated entries."
                      />
                    </TableBodyRow>
                    <TableBodyRow>
                      <TableBodyItem
                        title="Form Completion"
                        colSpan={12}
                        className="bg-white pe-4"
                      />
                    </TableBodyRow>
                    <TableBodyRow>
                      <TableBodyItem
                        className="align-content-start bg-white py-3 w-50"
                        colSpan={6}
                        text="Incomplete forms often need to be returned to patients for correction."
                      />
                      <TableBodyItem
                        className="align-content-start bg-white py-3 w-50"
                        colSpan={6}
                        text="Built-in prompts and required fields ensure complete submissions."
                      />
                    </TableBodyRow>
                    <TableBodyRow>
                      <TableBodyItem
                        title="Time & Efficiency"
                        colSpan={12}
                        className="bg-gray-100 pe-4"
                      />
                    </TableBodyRow>
                    <TableBodyRow>
                      <TableBodyItem
                        className="align-content-start bg-gray-100 py-3 w-50"
                        colSpan={6}
                        text="Staff spends significant time on administrative tasks."
                      />
                      <TableBodyItem
                        className="align-content-start bg-gray-100 py-3 w-50"
                        colSpan={6}
                        text="Staff is freed up to focus on patient care and other tasks."
                      />
                    </TableBodyRow>
                    <TableBodyRow>
                      <TableBodyItem
                        title="Document Storage"
                        colSpan={12}
                        className="bg-white pe-4"
                      />
                    </TableBodyRow>
                    <TableBodyRow>
                      <TableBodyItem
                        className="align-content-start bg-white py-3 w-50"
                        colSpan={6}
                        text="Physical storage is needed; there is a risk of loss or damage."
                      />
                      <TableBodyItem
                        className="align-content-start bg-white py-3 w-50"
                        colSpan={6}
                        text="Secure digital storage with easy access and no risk of physical loss."
                      />
                    </TableBodyRow>
                    <TableBodyRow>
                      <TableBodyItem
                        title="Patient Payments"
                        colSpan={12}
                        className="bg-gray-100 pe-4"
                      />
                    </TableBodyRow>
                    <TableBodyRow>
                      <TableBodyItem
                        className="align-content-start bg-gray-100 py-3 w-50"
                        colSpan={6}
                        text="Handled separately, often causing delays and inefficiencies."
                      />
                      <TableBodyItem
                        className="align-content-start bg-gray-100 py-3 w-50"
                        colSpan={6}
                        text="Integrated payment options within the digital intake forms."
                      />
                    </TableBodyRow>
                    <TableBodyRow>
                      <TableBodyItem
                        title="Environmental Impact"
                        colSpan={12}
                        className="bg-white pe-4"
                      />
                    </TableBodyRow>
                    <TableBodyRow>
                      <TableBodyItem
                        className="align-content-start bg-white py-3 w-50"
                        colSpan={6}
                        text="High paper usage and waste."
                      />
                      <TableBodyItem
                        className="align-content-start bg-white py-3 w-50"
                        colSpan={6}
                        text="Completely paperless; eco-friendly and sustainable."
                      />
                    </TableBodyRow>
                    <TableBodyRow>
                      <TableBodyItem
                        title="Compliance"
                        colSpan={12}
                        className="bg-gray-100 pe-4"
                      />
                    </TableBodyRow>
                    <TableBodyRow>
                      <TableBodyItem
                        className="align-content-start bg-gray-100 py-3 w-50"
                        colSpan={6}
                        text={
                          <>
                            Risk of{' '}
                            <a href="/blog/consolidate-your-software-for-patient-engagement-to-avoid-security-compliance-risks/">
                              compliance issues
                            </a>{' '}
                            with unsecured paper forms.
                          </>
                        }
                      />
                      <TableBodyItem
                        className="align-content-start bg-gray-100 py-3 w-50"
                        colSpan={6}
                        text={
                          <>
                            <a href="/blog/hipaa-compliant-healthcare-applications-part-2-of-3-what-you-need-to-know-about-audits-in-healthcare/">
                              HIPAA-compliant
                            </a>{' '}
                            digital forms with enhanced data security.
                          </>
                        }
                      />
                    </TableBodyRow>
                  </TableBody>
                </TableWrapper>
              )}
            </Col>
          </Row>
          <p className="font-size-lg fw-bold mb-0">
            What should you look for in a patient engagement solution?{' '}
            <a
              href="/patient-engagement-platforms-compared/"
              className="font-family-base fw-normal mb-0"
            >
              Compare BridgeInteract with Phreesia, Intelichart, and more
            </a>
            .
          </p>
        </Container>
      </Section>

      <Section id="why-choose-bridgeinteract?">
        <Container>
          <Row>
            <Col>
              <h2 className="fw-bolder">Why Choose BridgeInteract?</h2>
              <p className="font-size-sm mb-5">
                With BridgeInteract’s patient intake solutions, healthcare
                organizations can deliver a high-quality, intuitive intake
                experience that sets the stage for future interactions.
                <br />
                By digitizing your patient intake management, we enable your
                staff to focus on patient care rather than administrative tasks
                while patients benefit from a smooth, secure, and convenient
                intake process.
              </p>
              <h3 className="fw-bold">
                Modular Design For Flexible Patient Engagement
              </h3>
              <p className="font-size-sm">
                <a href="/patient-engagement-solution/">BridgeInteract</a>{' '}
                offers a modular approach to patient engagement. You can pick
                and choose modules as standalone solutions or combine them into
                a comprehensive, fully integrated system. This flexibility lets
                your organization build a tailored suite of tools that{' '}
                <a href="/blog/how-to-improve-the-patient-experience/">
                  enhances patient experience
                </a>{' '}
                and operational efficiency.
              </p>
              <SimpleCard className="py-5">
                <SimpleCardItem
                  col="4"
                  title="Customizable to your needs:"
                  text="Each module is fully white-labeled and can be adapted to meet your branding and workflow needs."
                  className="mb-4"
                />
                <SimpleCardItem
                  col="4"
                  title="Seamless compatibility:"
                  text={
                    <>
                      Works with any{' '}
                      <a href="/integration-services/">
                        EHR, RCM, PM, HIE, or RIS system
                      </a>
                      , ensuring smooth integration into your existing
                      infrastructure.
                    </>
                  }
                  className="mb-4"
                />
                <SimpleCardItem
                  col="4"
                  title="HIPAA-compliant:"
                  text={
                    <>
                      Every BridgeInteract module meets the{' '}
                      <a href="/blog/healthcare-application-security-how-to-protect-patient-data/">
                        highest data security and patient privacy standards
                      </a>
                      .
                    </>
                  }
                  className="mb-4"
                />
              </SimpleCard>
              <h4 className="fw-bolder mb-0">
                Example module integrations include:
              </h4>
              <SimpleCard className="has-border-top-blue gap-4 gap-xl-0 pt-5">
                <SimpleCardItem
                  col="6"
                  title="Bridge Intake + Bridge Scheduling:"
                  text={
                    <>
                      Allow patients to complete their intake forms online
                      immediately after booking their appointment.
                      <IconButton
                        className="c-icon-button__big c-icon-button__small mt-4"
                        href="/patient-scheduling-software/"
                      >
                        Learn More About Bridge Scheduling{' '}
                        <FaArrowRight
                          size={16}
                          className="d-none d-lg-block ms-lg-2"
                        />
                      </IconButton>
                    </>
                  }
                  iconRight={intakeShcedulingIcon}
                  showIconRight
                />
                <SimpleCardItem
                  col="6"
                  title="Bridge Intake + Bridge Pay:"
                  text={
                    <>
                      Enable patients to submit co-pays and check insurance
                      coverage in real time as part of the intake process.
                      <IconButton
                        className="c-icon-button__big c-icon-button__small mt-4"
                        href="/patient-payments/"
                      >
                        Learn More About Bridge Pay{' '}
                        <FaArrowRight
                          size={16}
                          className="d-none d-lg-block ms-lg-2"
                        />
                      </IconButton>
                    </>
                  }
                  iconRight={payAltIcon}
                  showIconRight
                />
              </SimpleCard>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section>
        <Container>
          <Row>
            <Col>
              <Banner
                title="Build Your Ideal Patient Engagement System"
                text="With BridgeInteract’s modular design, you can consolidate powerful tools to create a unified patient engagement platform. Or, start with a single solution and expand as your needs grow."
                label="Learn More"
                uri="/view-a-demo/"
                image={banner}
                className="bg-secondary"
              />
            </Col>
          </Row>
        </Container>
      </Section>

      <Section>
        <Container>
          <Row className="align-items-center">
            <Col>
              <h3 className="fw-bold">Bridge Intake Features</h3>
            </Col>
          </Row>
          <Row className="align-items-center mb-4 mb-lg-0">
            <Col className="my-0 my-lg-4 pe-lg-5" lg={6}>
              <ul>
                <li className="font-size-sm">
                  <strong>Mobile-Ready & Platform-Neutral:</strong> Patients can
                  check in and complete forms on their preferred devices.
                </li>
                <li className="font-size-sm">
                  <strong>Unbeatable User Experience:</strong> Bridge Intake is
                  also a part of{' '}
                  <a href="/patient-engagement-solution/">
                    the BridgeInteract patient engagement solution
                  </a>
                  . An intuitive interface,{' '}
                  <a href="/blog/streamlining-the-healthcare-experience-with-passwordless-logins/">
                    passwordless logins
                  </a>
                  , and{' '}
                  <a href="/patient-messaging-software-solution/">
                    integrated secure messaging
                  </a>{' '}
                  make BridgeInteract an app that patients love to use.
                </li>
                <li className="font-size-sm">
                  <strong>Seamless EHR/PM Integration:</strong>{' '}
                  <a href="/integration-services/">
                    Bi-directional integration
                  </a>{' '}
                  ensures that data flows seamlessly between BridgeInteract’s
                  patient intake management software and your existing EHR or PM
                  systems. Demographic and clinical data are pre-populated,
                  while new information is instantly updated.
                </li>
              </ul>
            </Col>
            <Col className="my-0 my-lg-4" lg={6}>
              <div className="img-ratio h-0">
                <StaticImage
                  src="../images/bridge-intake-features-01.png"
                  alt=""
                  className="position-absolute top-0 start-0 w-100 h-100"
                />
              </div>
            </Col>
          </Row>
          <Row className="align-items-center flex-column-reverse flex-lg-row">
            <Col className="my-0 my-lg-4" lg={6}>
              <div className="img-ratio h-0">
                <StaticImage
                  src="../images/bridge-intake-features-02.png"
                  alt=""
                  className="position-absolute top-0 start-0 w-100 h-100"
                />
              </div>
            </Col>
            <Col className="my-0 my-lg-4 ps-lg-5" lg={6}>
              <ul>
                <li className="font-size-sm">
                  <strong>Forms On Demand:</strong> Send any form directly from
                  your EHR/PM user interface, perfect for unique circumstances
                  or during phone consultations.
                </li>
                <li className="font-size-sm">
                  <strong>Intake Analytics:</strong> Leverage detailed insights
                  to monitor check-in volumes, devices used, and visit metrics
                  to optimize your workflows.
                </li>
                <li className="font-size-sm">
                  <strong>Customizable Forms & Drag-And-Drop Interface:</strong>{' '}
                  Easily design custom forms tailored to your organization’s
                  unique requirements, with flexible options based on
                  appointment type, medical history, or patient demographics.
                </li>
                <li className="font-size-sm">
                  <strong>Telehealth Enabled:</strong> Automatically connect
                  patients to{' '}
                  <a href="/telehealth-solution/">telehealth appointments</a> at
                  the end of the intake process, simplifying virtual care
                  visits.
                </li>
              </ul>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col className="mt-4 my-lg-4 pe-lg-5" lg={6}>
              <ul>
                <li className="font-size-sm">
                  <strong>Real-Time Insurance Verification:</strong> Automate
                  eligibility verification and instantly access copay,
                  deductible, and coinsurance information.
                </li>
                <li className="font-size-sm">
                  <strong>Safe & Secure Payments:</strong> Collect{' '}
                  <a href="/patient-payments/">PCI-compliant payments</a> and
                  post them directly to your EHR/PM system in real time,
                  minimizing time spent on payment reconciliation.
                </li>
                <li className="font-size-sm">
                  <strong>Consent Forms With E-Signature:</strong> Capture
                  signatures for financial, privacy, and clinical policies,
                  automating reminders to ensure all necessary forms are
                  completed.
                </li>
                <li className="font-size-sm">
                  <strong>Check-in kiosks/tablets:</strong> Enjoy uninterrupted
                  digital workflows and cut down on waiting times with{' '}
                  <a href="/patient-engagement-platforms-compared/">
                    check-in-ready devices for your reception area
                  </a>
                  .
                </li>
              </ul>
            </Col>
            <Col className="my-0 my-lg-4" lg={6}>
              <div className="img-ratio h-0">
                <StaticImage
                  src="../images/bridge-intake-features-03.png"
                  alt=""
                  className="position-absolute top-0 start-0 w-100 h-100"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section variant="bg-gray">
        <Container>
          <Row>
            <Col>
              <h2 className="fw-bolder">Digital Intake Solutions Compared</h2>
              <p className="font-size-sm mb-4">
                While there are multiple competing digital intake solutions on
                the market, only one stands out as feature-complete, with a
                flexible form builder and the option for integrated in-clinic
                devices.
              </p>
            </Col>
          </Row>
          <Row className="py-lg-4">
            <Col>
              {isDesktop ? (
                <TableWrapper>
                  <TableHead>
                    <TableHeadItem
                      titleTag
                      title="Intake"
                      colSpan={5}
                      className="bg-light"
                    />
                    <TableHeadItem
                      title="Bridge"
                      colSpan={1}
                      className="text-white bg-secondary"
                    />
                    <TableHeadItem
                      title="InteliChart"
                      colSpan={1}
                      className="bg-gray-200"
                    />
                    <TableHeadItem
                      title="Phreesia"
                      colSpan={1}
                      className="bg-gray-200"
                    />
                    <TableHeadItem
                      title="Relatient"
                      colSpan={1}
                      className="bg-gray-200"
                    />
                  </TableHead>
                  <TableBody>
                    <TableBodyRow>
                      <TableBodyItem
                        title="Patient Intake Software"
                        text="Ability to fill out check-in and/or intake/registration forms from patient's device or via tablet/kiosk."
                        colSpan={5}
                        className="pe-4"
                      />
                      <TableBodyItem
                        className="text-center align-content-center bg-secondary bg-opacity-10"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                    </TableBodyRow>
                    <TableBodyRow>
                      <TableBodyItem
                        title="Check-In Kiosk / Tablet"
                        text="Self-service kiosks or tablets for patients to check in at the healthcare facility."
                        colSpan={5}
                        className="pe-4"
                      />
                      <TableBodyItem
                        className="text-center align-content-center bg-secondary bg-opacity-10"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="cross"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="cross"
                      />
                    </TableBodyRow>
                  </TableBody>
                </TableWrapper>
              ) : (
                <TableDropdown className="mb-3" title="Intake">
                  <TableDropdownItem
                    title="Patient Intake Software"
                    text="Ability to fill out check-in and/or intake/registration forms from patient's device or via tablet/kiosk."
                  >
                    <TableWrapper>
                      <TableBody>
                        <TableBodyRow>
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                        </TableBodyRow>
                      </TableBody>
                    </TableWrapper>
                  </TableDropdownItem>
                  <TableDropdownItem
                    title="Check-In Kiosk / Tablet"
                    text="Self-service kiosks or tablets for patients to check in at the healthcare facility."
                  >
                    <TableWrapper>
                      <TableBody>
                        <TableBodyRow>
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="cross"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="cross"
                          />
                        </TableBodyRow>
                      </TableBody>
                    </TableWrapper>
                  </TableDropdownItem>
                </TableDropdown>
              )}
            </Col>
          </Row>
          <Row className="py-lg-4">
            <Col>
              {isDesktop ? (
                <TableWrapper>
                  <TableHead>
                    <TableHeadItem
                      titleTag
                      title="Mobile"
                      colSpan={5}
                      className="bg-light"
                    />
                    <TableHeadItem
                      title="Bridge"
                      colSpan={1}
                      className="text-white bg-secondary"
                    />
                    <TableHeadItem
                      title="InteliChart"
                      colSpan={1}
                      className="bg-gray-200"
                    />
                    <TableHeadItem
                      title="Phreesia"
                      colSpan={1}
                      className="bg-gray-200"
                    />
                    <TableHeadItem
                      title="Relatient"
                      colSpan={1}
                      className="bg-gray-200"
                    />
                  </TableHead>
                  <TableBody>
                    <TableBodyRow>
                      <TableBodyItem
                        title="Mobile App"
                        text="Mobile app (aka. Digital Front Door) for patients to access key patient information, incl. patient portal functionality. Published to Apple App and Google Play Store."
                        colSpan={5}
                        className="pe-4"
                      />
                      <TableBodyItem
                        className="text-center align-content-center bg-secondary bg-opacity-10"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="cross"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="cross"
                      />
                    </TableBodyRow>
                    <TableBodyRow>
                      <TableBodyItem
                        title="Client-Branded Mobile App"
                        text="Mobile app is client-branded."
                        colSpan={5}
                        className="pe-4"
                      />
                      <TableBodyItem
                        className="text-center align-content-center bg-secondary bg-opacity-10"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="cross"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="cross"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="cross"
                      />
                    </TableBodyRow>
                  </TableBody>
                </TableWrapper>
              ) : (
                <TableDropdown className="mb-3" title="Mobile">
                  <TableDropdownItem
                    title="Mobile App"
                    text="Mobile app (aka. Digital Front Door) for patients to access key patient information, incl. patient portal functionality. Published to Apple App and Google Play Store."
                  >
                    <TableWrapper>
                      <TableBody>
                        <TableBodyRow>
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="cross"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="cross"
                          />
                        </TableBodyRow>
                      </TableBody>
                    </TableWrapper>
                  </TableDropdownItem>
                  <TableDropdownItem
                    title="Client-Branded Mobile App"
                    text="Mobile app is client-branded."
                  >
                    <TableWrapper>
                      <TableBody>
                        <TableBodyRow>
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="cross"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="cross"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="cross"
                          />
                        </TableBodyRow>
                      </TableBody>
                    </TableWrapper>
                  </TableDropdownItem>
                </TableDropdown>
              )}
            </Col>
          </Row>
          <Row className="py-lg-4">
            <Col>
              {isDesktop ? (
                <TableWrapper>
                  <TableHead>
                    <TableHeadItem
                      titleTag
                      title="Forms"
                      colSpan={5}
                      className="bg-light"
                    />
                    <TableHeadItem
                      title="Bridge"
                      colSpan={1}
                      className="text-white bg-secondary"
                    />
                    <TableHeadItem
                      title="InteliChart"
                      colSpan={1}
                      className="bg-gray-200"
                    />
                    <TableHeadItem
                      title="Phreesia"
                      colSpan={1}
                      className="bg-gray-200"
                    />
                    <TableHeadItem
                      title="Relatient"
                      colSpan={1}
                      className="bg-gray-200"
                    />
                  </TableHead>
                  <TableBody>
                    <TableBodyRow>
                      <TableBodyItem
                        title="Patient Surveys and Reviews"
                        text="Ability for patients to complete surveys remotely via their own device."
                        colSpan={5}
                        className="pe-4"
                      />
                      <TableBodyItem
                        className="text-center align-content-center bg-secondary bg-opacity-10"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                    </TableBodyRow>
                    <TableBodyRow>
                      <TableBodyItem
                        title="Screeners"
                        text="Patient screeners and health risk assessments, including PHQ-9, GAD-7, CHAT, etc. integrated to EHR."
                        colSpan={5}
                        className="pe-4"
                      />
                      <TableBodyItem
                        className="text-center align-content-center bg-secondary bg-opacity-10"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="cross"
                      />
                    </TableBodyRow>
                    <TableBodyRow>
                      <TableBodyItem
                        title="Form Builder"
                        text="Allows healthcare providers to create custom digital forms for patient use."
                        colSpan={5}
                        className="pe-4"
                      />
                      <TableBodyItem
                        className="text-center align-content-center bg-secondary bg-opacity-10"
                        colSpan={1}
                        icon
                        iconType="check"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="cross"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="cross"
                      />
                      <TableBodyItem
                        className="text-center align-content-center"
                        colSpan={1}
                        icon
                        iconType="cross"
                      />
                    </TableBodyRow>
                  </TableBody>
                </TableWrapper>
              ) : (
                <TableDropdown className="mb-3" title="Forms">
                  <TableDropdownItem
                    title="Patient Surveys and Reviews"
                    text="Ability for patients to complete surveys remotely via their own device."
                  >
                    <TableWrapper>
                      <TableBody>
                        <TableBodyRow>
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                        </TableBodyRow>
                      </TableBody>
                    </TableWrapper>
                  </TableDropdownItem>
                  <TableDropdownItem
                    title="Screeners"
                    text="Patient screeners and health risk assessments, including PHQ-9, GAD-7, CHAT, etc. integrated to EHR."
                  >
                    <TableWrapper>
                      <TableBody>
                        <TableBodyRow>
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="cross"
                          />
                        </TableBodyRow>
                      </TableBody>
                    </TableWrapper>
                  </TableDropdownItem>
                  <TableDropdownItem
                    title="Form Builder"
                    text="Allows healthcare providers to create custom digital forms for patient use."
                  >
                    <TableWrapper>
                      <TableBody>
                        <TableBodyRow>
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="check"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="cross"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="cross"
                          />
                          <TableBodyItem
                            className="text-center align-content-center"
                            colSpan={1}
                            icon
                            iconType="cross"
                          />
                        </TableBodyRow>
                      </TableBody>
                    </TableWrapper>
                  </TableDropdownItem>
                </TableDropdown>
              )}
            </Col>
          </Row>
          <p className="font-size-sm mb-0 mt-4">
            The benefits of BridgeInteract don’t just stop at Intake.{' '}
            <a href="/patient-engagement-platforms-compared/">
              Compare BridgeInteract with other patient engagement solutions
            </a>
            .
          </p>
        </Container>
      </Section>

      <Section id="get-started-with-patient-intake-software">
        <Container>
          <Row>
            <Col>
              <h2 className="fw-bolder">
                Experience The Industry’s Best Patient Intake Management
                Software
              </h2>
              <p className="font-size-sm">
                BridgeInteract empowers healthcare organizations to meet modern
                patient expectations with a fully integrated, customizable, and
                secure patient intake software solution that enhances patient
                satisfaction while optimizing practice efficiency.
              </p>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section variant="no-pt-top">
        <Container>
          <Row>
            <Col>
              <Banner
                title="Ready to take your patient intake process to the next level?"
                label="Request a Demo"
                uri="/view-a-demo/"
                image={banner2}
                className="bg-dark-blue"
              />
            </Col>
          </Row>
        </Container>
      </Section>

      <Section variant="no-pt-top">
        <Container>
          <Row>
            <Col>
              <h3 className="fw-bold">More Resources:</h3>
              <ul>
                <li>
                  <a
                    href="/healthcare-digital-front-door/"
                    className="font-size-lg"
                  >
                    Unlocking The Digital Front Door For Modern Healthcare
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/improving-patient-engagement-what-digital-tools-should-you-offer/"
                    className="font-size-lg"
                  >
                    Patient Engagement: What Digital Tools Should I Offer?
                  </a>
                </li>
                <li>
                  <a
                    href="/patient-engagement-platforms-compared/"
                    className="font-size-lg"
                  >
                    Digital Patient Engagement Solutions Compared
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/strategies-to-improve-patient-intake-efficiency/"
                    className="font-size-lg"
                  >
                    More Strategies To Improve Patient Intake Efficiency
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section id="faqs" variant="no-pt-top">
        <Container>
          <Row>
            <Col>
              <h2 className="fw-bold">FAQs</h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <Faqs className="mb-5">
                <FaqSingle question="What is BridgeInteract?">
                  <p className="font-size-sm">
                    BridgeInteract’s patient intake solution is part of our
                    comprehensive{' '}
                    <a href="/patient-engagement-solution/">
                      BridgeInteract Patient Engagement Solution
                    </a>
                    , a suite of digital tools designed to enhance every step of
                    the patient journey. Bridge Intake is available as a
                    standalone solution or with other modules in the
                    BridgeInteract payment engagement suite.
                  </p>
                  <p className="font-size-sm">Explore BridgeInteract</p>
                  <ul>
                    <li className="font-size-sm">
                      <a href="/patient-portal-software/">Bridge Portal:</a>{' '}
                      Centralized access to patient records and communication.
                    </li>
                    <li className="font-size-sm">
                      <a href="/patient-payments/">Bridge Pay:</a> Secure and
                      flexible patient payment options.
                    </li>
                    <li className="font-size-sm">
                      <a href="/patient-scheduling-software/">
                        Bridge Scheduling:
                      </a>{' '}
                      Streamlined patient scheduling and appointment management.
                    </li>
                    <li className="font-size-sm">
                      <a href="/telehealth-solution/">Bridge Virtual Care:</a>{' '}
                      Integrated telehealth solutions for virtual visits.
                    </li>
                    <li className="font-size-sm">
                      <a href="/patient-messaging-solution/">
                        Bridge Communication:
                      </a>{' '}
                      Powerful patient messaging and reminders.
                    </li>
                    <li className="font-size-sm">
                      <a href="/patient-engagement-mobile-app/">
                        Bridge Mobile:
                      </a>{' '}
                      A patient-facing mobile app for on-the-go access.
                    </li>
                    <li className="font-size-sm">
                      <a href="/patient-intake-solution/">Bridge Intake:</a>{' '}
                      Advanced patient intake software for registration and
                      check-in.
                    </li>
                    <li className="font-size-sm">
                      <a href="/task-management/">Bridge Tasking:</a> Manage and
                      assign patient-related tasks to staff members.
                    </li>
                    <li className="font-size-sm">
                      <a href="/task-management/">Bridge Interface:</a> Seamless
                      data exchange and integration capabilities.
                    </li>
                  </ul>
                </FaqSingle>
                <FaqSingle question="Can I create custom patient intake forms?">
                  <p className="font-size-sm">
                    Yes. Bridge offers pre-built intake forms that can be easily
                    customized to your specific requirements. There is a small
                    hourly charge for creating a{' '}
                    <a href="/patient-intake-software/">custom intake form</a>.
                    Most custom forms take just a few hours to complete.
                  </p>
                  <p className="font-size-sm">
                    As well as intake forms, BridgeInteract allows you to create
                    Health Risk Assessments (HRA), surveys, pre-op forms, and
                    more.
                  </p>
                </FaqSingle>
                <FaqSingle question="Can BridgeInteract check for insurance eligibility and provide service estimations?">
                  <p className="font-size-sm">
                    Yes. BridgeInteract works with a real-time EDI service for
                    insurance eligibility and service estimations, which are
                    typically checked during the{' '}
                    <a href="/patient-intake-solution/">
                      patient intake/check-in process
                    </a>
                    .
                  </p>
                </FaqSingle>
                <FaqSingle question="Does BridgeInteract have the ability to gather form data before a telemedicine/telehealth visit?">
                  <p className="font-size-sm">
                    Through the{' '}
                    <a href="/telehealth-solution/">Bridge Virtual Care</a>{' '}
                    module, BridgeInteract allows patients to fill out forms
                    required for a particular appointment type as part of an
                    automated workflow. After completing the necessary forms,
                    the patient is taken directly to their telehealth video
                    session. During any of these steps, they can chat with staff
                    for help via{' '}
                    <a href="/patient-messaging-solution/">
                      Bridge Communication
                    </a>
                    . Before a telehealth visit, patients are typically required
                    to sign a{' '}
                    <a href="/blog/telehealth-consent-agreement/">
                      telehealth consent form
                    </a>
                    .
                  </p>
                </FaqSingle>
              </Faqs>
            </Col>
          </Row>
        </Container>
      </Section>
    </Layout>
  );
};

export default PatientIntakeSoftware;
